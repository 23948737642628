import Config from "@/utils/config"
import {
	login
} from "@/api/auth/login"

import {
	mapGetters
} from "vuex"
import { categoryList } from "@/api/resource.js"
import { brandTypeList, goodsCategoryList, goodsNameList, goodsLists, checkAuthCode } from '@/api/accredit.js'
export default {
	name: "index",
	data: () => {
		return {
			current_mode:1,
			preselection:'',
			current_category:0,
			category_id:'',
			all_category:[],
			toUid:'',
			brand_type_list:[{id:'',name:'全部'}],
			brand_type:0,
			class_list:[],
			class_names:[],
			goods_name_list:[{goods_name:'全部'}],
			goods_name:'',
			goods_wall_list:[],
			goods_list:[],
			loading:true,
			auth_code:'',
			role:'buyer',
			authorization_info:null,
			search_auth_code:'',
			flag:false,
			customer_agent_member_id:'',
		}
	},
	watch: {
		
	},
	created() {
		if(this.$route.query.customer_agent_member_id) this.customer_agent_member_id = this.$route.query.customer_agent_member_id;
		this.preselection = this.$route.query.mode ? this.$route.query.mode : 1 ;
		this.current_mode = this.$route.query.mode ? this.$route.query.mode : 1;
		this.getCategoryList();
		this.getAccreditTypeList();
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll);
		if(localStorage.getItem('isKefuIndex') && this.token){
			this.toUid = 'member_' + localStorage.getItem('isKefuIndex');
			localStorage.removeItem('isKefuIndex');
			this.$refs.servicerMessage.show();
		}
	},
	computed: {
		...mapGetters(["defaultHeadImage", "addonIsExit", "defaultGoodsImage", "member", "siteInfo", "cartCount","city","token"]),
	},
	methods: {
		copyAuthInfo(){
			if(!this.authorization_info){
				this.$message.error('请先查询授权号信息');
				return;
			}
			var str = this.authorization_info.auth_company+'\r\n'+this.authorization_info.order_name+'\r\n'+this.authorization_info.order_goods[0].category_name+'\r\n'+this.authorization_info.order_goods[0].reg_no+'\r\n'+this.authorization_info.auth_platform+'\r\n'+this.authorization_info.auth_store_type+'\r\n'+this.authorization_info.auth_begin_date + '至' + this.authorization_info.auth_end_date;
			this.$util.copy(str);
		},
		searchAuthCode(){
			if(!this.token){
				this.$message.error('请先去登录');
				setTimeout(()=>{
					this.$router.push({path:'/login',query:{redirect:'/index?mode=2'}})
				},800)
				return;
			}
			if(!this.auth_code){
				this.$message.error('请填写搜索的授权号');
				return;
			}
			if( this.search_auth_code.trim() == this.auth_code.trim()){
				return;
			}
			if(this.flag) return;
			this.flag = true;
			checkAuthCode({
				auth_code:this.auth_code,
				role:this.role
			})
			.then(res=>{
				if(res.code>=0){
					this.authorization_info = res.data ? res.data : null;
					this.search_auth_code = this.auth_code;
					if(res.data){
					}else{
						this.$message.error('暂无相关授权信息');
					}
					this.flag = false;
				}else{
					this.$message.error(res.message);
					this.flag = false;
				}
			})
			.catch(err=>{
				this.$message.error(err.message);
				this.flag = false;
			})
		},
		toPayAccredit(data=null){
			var select_data = {};
			if(data){
				if(data.goods_id){
					select_data = data;
				}else{
					return;
				}
			}else{
				//默认第一个
				select_data = this.goods_wall_list[0];
			}
			localStorage.setItem('customer_agent_member_id',this.customer_agent_member_id);
			localStorage.setItem('select_accredit',JSON.stringify(select_data));
			this.$router.push('/payment');
		},
		changeCondition(mode,e){
			switch (mode){
				case 'type':
					this.getGoodsCategoryList();
					break;
				case 'class':
					this.getGoodsNameList();
					break;
				case 'name':
					this.getGoodsLists();
					break;
				default:
					break;
			}
		},
		getGoodsLists(){
			goodsLists({
				brand_type:this.brand_type,
				category_ids:this.class_names.toString(),
				goods_name:this.goods_name=='全部'?'':this.goods_name
			})
			.then(res=>{
				if(res.code>=0){
					if(res.data.length>25){
						this.goods_wall_list = [];
						this.goods_list = [];
						res.data.forEach((item,index)=>{
							if(index<25){
								this.goods_wall_list.push(item);
							}else{
								this.goods_list.push(item);
							}
						})
					}else{
						this.goods_wall_list = res.data;
						this.goods_list = [];
						for(var i = res.data.length ; i<25;i++ ){
							this.goods_wall_list.push({goods_name:''});
						}
					}
					this.loading = false;
				}
			})
		},
		getGoodsNameList(){
			goodsNameList({
				brand_type:this.brand_type,
				category_ids:this.class_names.toString()
			})
			.then(res=>{
				if(res.code>=0){
					this.goods_name_list = [{goods_name:'全部'}];
					this.goods_name_list.push(...res.data);
					this.goods_name = this.goods_name_list[0].goods_name;
					this.getGoodsLists();
				}
			})
		},
		getGoodsCategoryList(){
			goodsCategoryList({
				brand_type:this.brand_type
			})
			.then(res=>{
				if(res.code>=0){
					this.class_list = [];
					// this.class_list = [{category_id:'',category_name:'全部'}];
					this.class_list.push(...res.data);
					// this.class_names.push(this.class_list[0].category_id)
					this.getGoodsNameList();
				}
			})
		},
		getAccreditTypeList(){
			brandTypeList()
			.then(res=>{
				if(res.code>=0){
					this.brand_type_list = [{id:'',name:'全部'}]
					this.brand_type_list.push(...res.data);
					this.brand_type = this.brand_type_list[0].id;
					this.getGoodsCategoryList();
				}
			})
		},
		toViewChat(id){
			if(!this.token){
				localStorage.setItem("isKefuIndex",id);
				this.$message.warning('请先进行登录');
				setTimeout(()=>{
					this.$router.push({path: '/login', query: {redirect: "/"}});
				},800)
				return;
			}
			this.toUid = 'member_'+id;
			this.$refs.servicerMessage.show();
		},
		getCategoryList(){
			categoryList({
				goods_type:this.current_mode==1?'resource':'demand'
			})
			.then(res=>{
				if(res.code>=0){
					this.all_category = [{'category_id':'','category_name':'推荐'}]
					this.all_category.push(...this.$util.deepClone(res.data));
					this.category_id = this.all_category[0].category_id;
					this.current_category = 0;
				}
			})
		},
		chooseCategory(category_id,index,class_id){
			this.goods_loading = true;
			this.current_category = index;
			this.category_id = category_id;
			let lastSpot = this.$refs.scrollBox.scrollLeft;
			const nextSpace = 50; //每次移动距离
			let scrollItemTimer = setInterval(() => {
				this.$nextTick(() => {
					let offsetWidth = this.$refs.scrollItem[this.current_category].offsetWidth;
					let scrollLeft = this.$refs.scrollItem[this.current_category].offsetLeft;
					const containWidth = this.$refs.scrollBox.offsetWidth;
					if(this.$refs.scrollItem[0].offsetLeft == 0){
						var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2;
					}else{
						var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2 - this.$refs.scrollItem[0].offsetLeft;
					}
					if (Math.abs(lastSpot - resultSpot) < nextSpace) {
						clearInterval(scrollItemTimer);
					}
					if (resultSpot >= lastSpot) {
						lastSpot = lastSpot + nextSpace;
					} else {
						lastSpot = lastSpot - nextSpace;
					}
					this.$refs.scrollBox.scrollTo(lastSpot, 0);
				});
			}, 15);
		},
		changeMode(mode){
			this.current_mode = mode;
			this.getCategoryList();
			// this.getGoodsList();
		},
		// 监听滚动条
		handleScroll() {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if (scrollTop >= 680) {
				this.isShow = true
			} else {
				this.isShow = false
			}
		},
		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
			window.removeEventListener("scroll", this.handleScroll)
		},
	}
}
