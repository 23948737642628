<template>
	<div class="index">
		<top-search :diyJump="true" :preselection="preselection" @changeMode="changeMode"></top-search>
		<div class="category_lists" ref="scrollBox">
			<div class="item" ref="scrollItem" @click="chooseCategory(item.category_id,index,item.attr_class_id)" :class="category_id == item.category_id ? 'active' : ''" v-for="(item,index) in all_category" :key="index">{{item.category_name}}<div :class="category_id == item.category_id ? 'active' : ''" class="line"></div>
			</div>
		</div>
		<template v-if="current_mode==1">
			<div class="screen_area">
				<div class="left">
					<el-select @change="changeCondition('type',$event)" v-model="brand_type" placeholder="授权类型" >
					    <el-option
					      v-for="(item,index) in brand_type_list"
					      :key="item.id"
					      :label="item.name"
					      :value="item.id">
					    </el-option>
					</el-select>
				</div>
				<div class="middle">
					<el-select @change="changeCondition('class',$event)" :multiple="true" v-model="class_names" placeholder="全部" >
					    <el-option	
					      v-for="(item,index) in class_list"
					      :key="item.category_id"
					      :label="item.category_name"
					      :value="item.category_id">
					    </el-option>
					</el-select>
					<el-select @change="changeCondition('name',$event)" v-model="goods_name" placeholder="名字" >
					    <el-option
					      v-for="(item,index) in goods_name_list"
					      :key="item.goods_name"
					      :label="item.goods_name"
					      :value="item.goods_name">
					    </el-option>
					</el-select>
				</div>
				<div class="right" @click="toPayAccredit()">授权</div>
			</div>
			<div v-loading="loading">
				<div class="accredit_wall">
					<!-- :style="item.goods_image?{backgroundImage:'url('+$img(item.goods_image)+')'}:{backgroundColor:'#f7f8fb'}" -->
					<div  v-for="(item,index) in goods_wall_list" :key="index" class="item"  @click="toPayAccredit(item)">
						<div class="name">{{item.goods_name}}</div>
						<!-- <div class="no">{{item.reg_no}}</div> -->
					</div>
				</div>
				<div class="accredit_list" v-if="goods_list.length">
					<div class="accredit_item" v-for="(item,index) in goods_list" :key="index" @click="toPayAccredit(item)">
						<div class="item_bg">
							<img class="bg" :src="$img(item.goods_image)" alt="">
							<!-- <div class="bg"></div> -->
						</div>
						<div class="name">{{item.goods_name}}</div>
					</div>
				</div>
			</div>
			
		</template>
		<template v-else>
			<div class="inquire_area">
				<div class="form">
					<div class="form_item">
						<div class="tit">授权号</div>
						<input class="shuru" type="text" @blur="searchAuthCode()" @keyup.enter="searchAuthCode()" v-model="auth_code">
					</div>
					<div class="form_item">
						<div class="tit">被授权</div>
						<div class="right">{{authorization_info ? authorization_info.auth_company : '无' }}</div>
					</div>
					<div class="form_item">
						<div class="tit">名称</div>
						<div class="right">{{authorization_info ? authorization_info.order_name : '无' }}</div>
					</div>
					<div class="form_item">
						<div class="tit">类别</div>
						<div class="right">{{authorization_info ? authorization_info.order_goods[0].category_name : '无' }}</div>
					</div>
					<div class="form_item">
						<div class="tit">注册号</div>
						<div class="right">{{authorization_info ? authorization_info.order_goods[0].reg_no : '无' }}</div>
					</div>
					<div class="form_item">
						<div class="tit">平台</div>
						<div class="right">{{authorization_info ? authorization_info.auth_platform : '无' }}</div>
					</div>
					
					<div class="form_item">
						<div class="tit">店铺</div>
						<div class="right">{{authorization_info ? authorization_info.auth_store_type : '无' }}</div>
					</div>
					<div class="form_item">
						<div class="tit">期限</div>
						<div class="right">{{authorization_info ? authorization_info.auth_begin_date + '至' + authorization_info.auth_end_date : '无' }}</div>
					</div>
				</div>
				<div class="actions">
					<div class="action" @click="copyAuthInfo()">复制</div>
				</div>
			</div>
		</template>
		<servicerMessage ref="servicerMessage" class="kefu" :toUid="toUid"></servicerMessage>
	</div>
</template>

<script>
	import topSearch from '@/components/top_search';
	import index from './_index/index.js';
	import servicerMessage from "@/components/message/servicerMessage"
	export default {
		name: 'index',
		components: {
			topSearch,
			servicerMessage
		},
		
		mixins: [index]
	};
</script>

<style lang="scss" scoped>
	@import './_index/index.scss';
</style>
<style lang="scss">
	.el-select-dropdown{
		.el-scrollbar{
			.el-scrollbar__view{
				.el-select-dropdown__item{
					text-align: center;
				}
			}
		}
	}
	.screen_area .left,
	.screen_area .middle{
		.el-select{
			width: 100%;
			background-color: #F7F8FB;
			border-radius: 5px;
			padding: 10px 0;
			.el-input--suffix{
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 1;
				.el-input__inner{
					width: 35%;
					background-color: transparent;
					border:none;
					padding: 0;
					height: auto;
					text-align: center;
					color: #30373D;
					font-size: 15px;
					line-height: 21px;
					&::placeholder{
						color: rgba(48, 55, 61, 0.50);
						font-family: "PingFang SC";
					}
				}
				.el-input__suffix{
					position: relative;
					right: 0;
					height: auto;
					text-align: left;
					.el-input__suffix-inner{
						line-height: 12px;
						display: block;
					}
				}
			}
		}
	}
	
	.screen_area .middle{
		.el-input__inner{
			min-width: 20%;
		}
	}
	.screen_area .el-select .el-input .el-select__caret {
	  transform: rotateZ(0deg);
	  width: 12px;
	  font-size: 12px;
	  line-height: 1;
	}
	.screen_area .el-select .el-input .el-select__caret::before {
	  content: "\e790";
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  top: 50%;
	  left: 50%;
	  color: #30373D !important;
	  transform: translate(-50%, -50%);
	}
	.screen_area .el-select{
		.el-select__tags{
			// justify-content: center;
		}
		.el-input__inner{
			&::placeholder{
				color: #30373D !important;
			}
		}
		.el-tag{
			margin-top: 0;
			margin-bottom: 0;
			color: #30373D;
			.el-tag__close{
				color: #fff;
				font-size: 11px;
				background-color: #30373D;
			}
		}
		.el-tag--small{
			height: 21px;
			line-height: 21px;
			border: none;
			background-color: transparent;
		}
	} 
	
</style>
